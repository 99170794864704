//./providers/wagmi/index.tsx
'use client';

import React from 'react';
import { RainbowKitAuthenticationProvider, RainbowKitProvider, darkTheme, DisclaimerComponent } from '@rainbow-me/rainbowkit';
import { WagmiConfig } from 'wagmi';
import CustomAvatar from './customAvatar';
import useAuth from './useAuth';
import WagmiConfigs from './wagmiConfigs';
const client = WagmiConfigs.client;
const chains = WagmiConfigs.chains;
const Disclaimer: DisclaimerComponent = ({
  Text,
  Link
}) => <Text data-sentry-element="Text" data-sentry-component="Disclaimer" data-sentry-source-file="index.tsx">
    By connecting your wallet, you agree to the{' '}
    <Link href="https://SimpFi.ai/terms" data-sentry-element="Link" data-sentry-source-file="index.tsx">Terms of Gangster</Link> and
    acknowledge you have read and understand the protocol{' '}
    <Link href="https://SimpFi.ai/legal" data-sentry-element="Link" data-sentry-source-file="index.tsx">Disclaimer</Link>
  </Text>;
const WagmiProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const {
    authStatus,
    authAdapter
  } = useAuth();
  return <WagmiConfig client={client} data-sentry-element="WagmiConfig" data-sentry-component="WagmiProvider" data-sentry-source-file="index.tsx">
      <RainbowKitAuthenticationProvider adapter={authAdapter} status={authStatus} data-sentry-element="RainbowKitAuthenticationProvider" data-sentry-source-file="index.tsx">
        <RainbowKitProvider coolMode chains={chains} modalSize="compact" theme={darkTheme({
        accentColor: '#26B400',
        accentColorForeground: 'white',
        borderRadius: 'small',
        fontStack: 'system',
        overlayBlur: 'large'
      })} appInfo={{
        appName: 'SimpFi ALPHA',
        disclaimer: Disclaimer,
        learnMoreUrl: 'https://SimpFi.Ai'
      }} avatar={CustomAvatar} data-sentry-element="RainbowKitProvider" data-sentry-source-file="index.tsx">
          {children}
        </RainbowKitProvider>
      </RainbowKitAuthenticationProvider>
    </WagmiConfig>;
};
export default WagmiProvider;