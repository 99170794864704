// #/src/providers/W3Provider/mockConnector.ts

'use client';

import { Wallet as RainbowWallet } from '@rainbow-me/rainbowkit';
import { Wallet } from "ethers";
import { Chain } from "wagmi";
import { MockConnector } from "wagmi/connectors/mock";

const makeSigner = () => {
  if (process.env.NEXT_PUBLIC_MOCK_WALLET_ENABLED === "true") {
    return process.env.NEXT_PUBLIC_MOCK_WALLET_KEY
      ? new Wallet(process.env.NEXT_PUBLIC_MOCK_WALLET_KEY)
      : Wallet.createRandom();
  } else {
    return undefined;
  }
}
const signer = makeSigner()

/**
 * Creates a mock wallet that can be supplied to RainbowKit.
 * If the mock wallet environment variable is not true, this will return undefined.
 */
export const mockWallet = (chains: Chain[]): RainbowWallet | undefined => {
  if (!signer) {
    return undefined;
  }
  return {
    id: 'mock',
    name: 'Mock Wallet',
    iconUrl: '/simpfinogrid.png',
    iconBackground: '#000',
    createConnector: () => ({
      connector: new MockConnector({
        chains: chains,
        options: {
          signer: signer,
          flags: {
            failConnect: false,
            failSwitchChain: false,
            isAuthorized: true,
            noSwitchChain: false,
          },
        },
      }),
    }),
  };
};