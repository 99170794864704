// #/services/workflows/getWorkflow.ts

import { join as pathJoin } from 'path';

import { logger } from '#/logger';
import readJsonFile from '#/services/s3/actions/json/readJsonFile';
import { IWorkflow } from '#/types/workspace';

import { PATH_USERS } from '../s3/filepaths';

export async function getWorkflow(args: {
  ownerAddress: string;
  workflowUuid: string;
  version?: string | number;
}): Promise<IWorkflow | null> {
  try {
    const { ownerAddress, workflowUuid, version } = args;
    // logger.info(`getWorkflow(${JSON.stringify(args)})`);

    const userHome = pathJoin(PATH_USERS, ownerAddress);
    const userWorkflowsFolderKey = pathJoin(userHome, 'workflows/');
    const workflowKey = version ? pathJoin(
      userWorkflowsFolderKey,
      `${workflowUuid}`,
      'versions/',
      `${version}.json`,
    ) : pathJoin(
      userWorkflowsFolderKey,
      `${workflowUuid}.json`,
    );

    logger.debug(`[getWorkflow] workflowKey: ${workflowKey}`);

    return await readJsonFile({
      s3ObjectKey: workflowKey,
    });
  } catch (error: any) {
    logger.error(`Error getting workflow: ${error.message}`);
    throw new Error(`Error getting workflow: ${error.message}`);
  }
}
export default getWorkflow;
