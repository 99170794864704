'use client';

import Image from 'next/image';
import { AvatarComponent } from '@rainbow-me/rainbowkit';
export const CustomAvatar: AvatarComponent = ({
  address,
  ensImage,
  size
}) => {
  return <div style={{
    backgroundColor: '#000000',
    borderRadius: 999,
    height: size,
    width: size
  }} data-sentry-component="CustomAvatar" data-sentry-source-file="customAvatar.tsx">
      <Image src={'/simpfinogrid.png'} alt='avatar' width={100} height={100} style={{
      borderRadius: 999
    }} data-sentry-element="Image" data-sentry-source-file="customAvatar.tsx" />
    </div>;
};
export default CustomAvatar;