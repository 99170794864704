import React, { useState } from 'react';
import { useRouter } from 'next/navigation';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import useAuth from '#/providers/W3Provider/useAuth';
import { useRedirectToDashboard } from '#/providers/W3Provider/useRedirect';
import NetworkSwitcher from '~/Layout/NetworkSwitcher';
const Wallet = () => {
  const router = useRouter();
  const {
    authStatus
  } = useAuth();
  const [loggingIn, setLoggingIn] = useState(false); // State to determine if the user has initiated the login

  const redirect = authStatus === 'authenticated';
  useRedirectToDashboard(redirect);
  const handleLoginClick = (openConnectModalFunction: () => void) => {
    setLoggingIn(true);
    openConnectModalFunction();
  };
  return <div className="flex flex-row gap-4 items-center" data-sentry-component="Wallet" data-sentry-source-file="wallet.tsx">
      {authStatus !== 'authenticated' && !loggingIn && <ConnectButton.Custom>
          {({
        openConnectModal,
        mounted,
        authenticationStatus
      }) => {
        const ready = mounted && authenticationStatus !== 'loading';
        if (authenticationStatus === 'authenticated') {
          router.push('/dashboard/workspaces');
        }
        return <div {...!ready && {
          'aria-hidden': true,
          style: {
            opacity: 0,
            pointerEvents: 'none',
            userSelect: 'none'
          }
        }}>
                <button className="bg-white text-black px-4 py-2 rounded-md font-semibold cursor-pointer hover:scale-[1.03] ease-in duration-100" onClick={() => handleLoginClick(openConnectModal)} type="button">
                  Log in
                </button>
              </div>;
      }}
        </ConnectButton.Custom>}
      <NetworkSwitcher data-sentry-element="NetworkSwitcher" data-sentry-source-file="wallet.tsx" />
      <ConnectButton label="Sign up" chainStatus="none" accountStatus="avatar" data-sentry-element="ConnectButton" data-sentry-source-file="wallet.tsx" />
    </div>;
};
export default Wallet;