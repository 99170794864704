'use client';

import React from 'react';
import HomeHero from '~/Layout/HomeHero';
import NavBar from '~/Layout/NavBar';
export default function Page() {
  return <div data-sentry-component="Page" data-sentry-source-file="page.tsx">
      <NavBar data-sentry-element="NavBar" data-sentry-source-file="page.tsx" />
      <HomeHero data-sentry-element="HomeHero" data-sentry-source-file="page.tsx" />
    </div>;
}